<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('inventory_transaction_report.from_date')}}</label>
                                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('inventory_transaction_report.to_date')}}</label>
                                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="type">{{$t('inventory_transaction_report.type')}}</label>
                                <select name="" id="type" v-model="filters.type" class="custom-select" required>
                                    <option value="SalesInvoice">{{ $t('inventory_transaction_report.sales_invoice') }}</option>
                                    <option value="SalesInvoiceRefund">{{ $t('inventory_transaction_report.sales_refund') }}</option>
                                    <option value="PurchaseInvoice">{{ $t('inventory_transaction_report.purchase_invoice') }}</option>
                                    <option value="PurchaseRefund">{{ $t('inventory_transaction_report.purchase_refund') }}</option>
                                    <option value="SalesCredit">{{ $t('inventory_transaction_report.credit_note') }}</option>
                                </select>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="inventory_id">{{$t('inventory_transaction_report.inventory')}}</label>
                                <multiselect v-model="inventory"
                                    :placeholder="$t('stocks_report.warehouse')"
                                    label="name"
                                    track-by="id"
                                    :options="inventories"
                                    :multiple="false"
                                    :taggable="true"
                                    :show-labels="false"
                                    :show-no-options="false"
                                    :show-no-results="false">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('inventory_transaction_report.product')}}</label>
                                <multiselect v-model="item"
                                    :placeholder="$t('inventory_transaction_report.product')"
                                    label="name"
                                    track-by="id"
                                    :options="items"
                                    :multiple="false"
                                    :taggable="false"
                                    :show-labels="false"
                                    :show-no-options="false"
                                    :show-no-results="false"
                                    :internal-search="false"
                                    @search-change="getItems(f_by, $event)">
                                </multiselect>
                            </div>

                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{$t('display_report')}}</b-button>
                                <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">

                            <div class="col-12 mt-10" id="summaryTableTitle">
                                <h4 class="text-center">{{ $t('inventory_transaction_report.primary_warehouse_transactions_summary')}}</h4>
                                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{filters.from_date}}</span> <span v-if="filters.to_date">{{ $t('to') }} {{filters.to_date}}</span></h5>
                            </div>
                            <div class="col-12 mt-10 d-flex justify-content-end">
                                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                    <download-excel
                                            class="dropdown-item"
                                            :fetch="dataReport"
                                            :fields="json_fields"
                                            :name="$t('inventory_transaction_report.primary_warehouse_transactions_summary')+'.xls'">
                                        <i class="la la-file-excel"></i>{{$t('excel')}}
                                    </download-excel>

                                    <button class="dropdown-item" @click="printData('summaryTable')">
                                        <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                    </button>
                                </b-dropdown>
                            </div>
                            <div class="col-12 mt-10">
                                <div class="table-responsive">
                                    <table class="table table-bordered text-center" id="summaryTable">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th colspan="5">{{$t('inventory_transaction_report.inward')}}</th>
                                            <th colspan="5">{{$t('inventory_transaction_report.outward')}}</th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th>{{$t('inventory_transaction_report.product_name')}}</th>
                                            <th>{{$t('inventory_transaction_report.purchase_invoices')}}</th>
                                            <th>{{$t('inventory_transaction_report.refund_receipts')}}</th>
                                            <th>{{$t('inventory_transaction_report.transfer')}}</th>
                                            <th>{{$t('inventory_transaction_report.manual')}}</th>
                                            <th>{{$t('inventory_transaction_report.total')}}</th>
                                            <th>{{$t('inventory_transaction_report.sales_invoices')}}</th>
                                            <th>{{$t('inventory_transaction_report.purchase_refund')}}</th>
                                            <th>{{$t('inventory_transaction_report.transfer')}}</th>
                                            <th>{{$t('inventory_transaction_report.manual')}}</th>
                                            <th>{{$t('inventory_transaction_report.total')}}</th>
                                            <th>{{$t('inventory_transaction_report.total_transactions')}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row, index) in data" :key="index">
                                            <td>{{row.product_name}}</td>
                                            <td>{{row.purchase_invoices}}</td>
                                            <td>{{row.refund_receipts}}</td>
                                            <td>{{row.inward_transfer}}</td>
                                            <td>{{row.inward_manual}}</td>
                                            <td>{{row.inward_total}}</td>
                                            <td>{{row.sales_invoices}}</td>
                                            <td>{{row.purchase_refund}}</td>
                                            <td>{{row.outward_transfer}}</td>
                                            <td>{{row.outward_manual}}</td>
                                            <td>{{row.outward_total}}</td>
                                            <td>{{row.total_transactions}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Filter -->
                <div class="col-12 text-center" v-if="page">
                    <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>
                    </button>
                </div>
            </div>
        </div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-inventory-transaction-report",
        data() {
            return {
                mainRoute: '/reports/stock-reports/inventory',
                mainRouteDependency: 'base/dependency',
                items: [],
                inventories: [],
                data: [],

                filters: {
                    from_date: null,
                    to_date: null,
                    type: null,
                    inventory_id: null,
                    item_id: null,
                },
                item: null,
                inventory : null,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page : 0,
            }
        },
        watch: {
            item: function (val) {
                if (val && val.id) {
                    this.filters.item_id = val.id;
                } else {
                    this.filters.item_id = null;
                }
            },
            inventory: function (val) {
                if (val && val.id) {
                    this.filters.inventory_id = val.id;
                } else {
                    this.filters.inventory_id = null;
                }
            }
        },
        computed: {

            json_fields: function () {
                let fields = {};
                fields[this.$t('inventory_transaction_report.product_name')] = 'product_name';
                fields[this.$t('inventory_transaction_report.purchase_invoices')] = 'purchase_invoices';
                fields[this.$t('inventory_transaction_report.refund_receipts')] = 'refund_receipts';
                fields[this.$t('inventory_transaction_report.transfer') + ` (${this.$t('inventory_transaction_report.inward')})`] = 'inward_transfer';
                fields[this.$t('inventory_transaction_report.manual') + ` (${this.$t('inventory_transaction_report.inward')})`] = 'inward_manual';
                fields[this.$t('inventory_transaction_report.total') + ` (${this.$t('inventory_transaction_report.inward')})`] = 'inward_total';
                fields[this.$t('inventory_transaction_report.sales_invoices')] = 'sales_invoices';
                fields[this.$t('inventory_transaction_report.purchase_refund')] = 'purchase_refund';
                fields[this.$t('inventory_transaction_report.transfer') + ` (${this.$t('inventory_transaction_report.outward')})`] = 'outward_transfer';
                fields[this.$t('inventory_transaction_report.manual') + ` (${this.$t('inventory_transaction_report.outward')})`] = 'outward_manual';
                fields[this.$t('inventory_transaction_report.total') + ` (${this.$t('inventory_transaction_report.outward')})`] = 'outward_total';
                fields[this.$t('inventory_transaction_report.total_transactions')] = 'total_transactions';

                return fields;
            },


        },
        
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.store_reports"),route:'/store/reports'}, {title: this.$t("MENU.inventory_transaction_report")}]);

            this.getItems();
            this.getInventories();
        },
        methods: {

            doFilter() {
                this.page = 1;
                this.getData();
            },
            loadMore() {
                this.page = this.page? (this.page + 1) : 1;
                this.getData();
            },
            resetFilter() {
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.type = null;
                this.filters.inventory_id = null;
                this.item = null;
                this.inventory = null;

                this.getData();

            },
            dataReport() {
                this.setReportLog('excel', 'inventory transaction');
                return this.data;
            },
            getData() {
                ApiService.get(this.mainRoute, {params: {...this.filters,page:(this.page? this.page : 1)}}).then((response) => {
                    this.data = response.data.data;
                });
            },
            // getItems() {
            //     ApiService.get(this.mainRouteDependency + "/items").then((response) => {
            //         this.items = response.data.data;
            //     });
            // },

            getItems(f_by = null, filter = null) {
              let _f_by = f_by? f_by : 'name';
              if (filter && filter.length >= 3 && _f_by)
                    ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
                        this.items = response.data.data;
                    });
            },

            getInventories() {
                ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
                    this.inventories = response.data.data;
                });
            },

            printData(tableId) {
                this.setReportLog('pdf', 'inventory transaction');
                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },

            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
